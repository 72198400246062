import React from 'react'
import PropTypes from 'prop-types'
import {graphql, StaticQuery} from 'gatsby'
import TagLink from './TagLink'

class ArticlePageTags extends React.Component {
  render() {
    const {data, tags} = this.props
    const allTags = data.allTagsYaml.edges[0].node.main;
    const tagNames = data.allTagsYaml.edges[0].node.data.reduce((obj, tag) => {
      obj[tag.tag] = tag.name
      return obj
    }, {})
    const groups = data.allMarkdownRemark.group.reduce((obj, group) => {
      obj[group.fieldValue] = group
      return obj
    }, {})

    return (
      <div className="article-page-tags my-5 mx-4 mx-sm-0">
        {allTags.map((tag, i) => (
          groups[tag] && tags.includes(tag) ? <TagLink key={i} tag={tag} name={tagNames[tag]} edges={groups[tag].edges} /> : null
        ))}
      </div>
    )
  }
}

ArticlePageTags.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

export default (props) => (
  <StaticQuery
    query={graphql`
query ArticlePageTagsQuery {
  allTagsYaml {
    edges {
      node {
        main
        data {
          tag
          name
        }
      }
    }
  }
  allMarkdownRemark(
    sort: {fields: [frontmatter___index], order: ASC},
    filter: {frontmatter: {templateKey: { eq: "article-page" }}}) {
    group(field: frontmatter___tags) {
      fieldValue
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            index
            title
          }
        }
      }
    }
  }
}
    `}
    render={(data) => <ArticlePageTags data={data} {...props} />}
  />
)
