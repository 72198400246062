import React from 'react'
import PropTypes from 'prop-types'
import {Link} from 'gatsby'
// import {Card, CardHeader, ListGroup, ListGroupItem} from 'reactstrap'

class TagLink extends React.Component {
  render() {
    const {edges, tag, name} = this.props
    console.debug(edges, tag, name)

    return (
      <div>
        <h5>その他の{name}</h5>
        <div>
          {edges &&
          edges.map(({node}, i) => (
            [<Link key={i} to={node.fields.slug} className="badge badge-secondary article-link">
              {node.frontmatter.title}
            </Link>, ' ']
          ))}
        </div>
      </div>
    )
  }
}

TagLink.propTypes = {
  edges: PropTypes.arrayOf(PropTypes.shape({
    node: PropTypes.shape({
      fields: PropTypes.shape({
        slug: PropTypes.string,
      }),
      frontmatter: PropTypes.shape({
        title: PropTypes.string,
      }),
    }),
  })),
}

export default TagLink