import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import {graphql, Link} from 'gatsby'
import Layout from '../components/Layout'
import Content, {HTMLContent} from '../components/Content'
import {Col, Row, Card, CardBody, CardTitle} from "reactstrap";
import BodySide from "../components/BodySide";
import Feedback from "../components/Feedback";
import ArticlePageTags from "../components/ArticlePageTags";

export const ArticlePageTemplate = ({
                                      content,
                                      contentComponent,
                                      description,
                                      prev,
                                      next,
                                      tags,
                                      title,
                                      helmet,
                                      data
                                    }) => {
  const PostContent = contentComponent || Content

  const isEnglish = tags && tags.includes('english')
  return (
    <div>
      <Row>
        <BodySide data={data} lang={isEnglish ? 'en' : 'ja'}/>
        <Col md="8">
          <Card>
            <CardBody>
              <section className="section">
                {helmet || ''}
                <div className="container content container-article">
                  <CardTitle>
                    <h1 className="display-7 articleTitle">{title}</h1>
                  </CardTitle>
                  <p>{description}</p>
                  <hr />
                  <PostContent content={content} className="articleSection"/>
                </div>
              </section>
            </CardBody>
          </Card>
          {isEnglish ? null : <Feedback /> }
          <div className="article-page-order my-5 mx-4 mx-sm-0">
            
            <h5>{isEnglish ? "Other pages" : "同カテゴリの他のページ" }</h5>
            <div className="justify-content-between d-block d-md-flex">
              {!prev ? null :
                <Link to={prev.node.fields.slug} className="badge badge-secondary article-link">
                  &lt;
                  {' '}
                  {prev.node.frontmatter.title}
                </Link>
              }
              {!next ? null :
                <Link to={next.node.fields.slug} className="badge badge-secondary article-link">
                  {next.node.frontmatter.title}
                  {' '}
                  &gt;
                </Link>
              }
            </div>
          </div>
          <ArticlePageTags tags={tags} />
        </Col>
      </Row>
    </div>
  )
}

ArticlePageTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
}

class ArticlePage extends React.Component {

  constructor(props) {
    super(props);
    console.debug('IndexPage', this.props)
    this.updateDimensions = this.updateDimensions.bind(this);
  }

  componentDidMount() {
    window.addEventListener('load', this.updateDimensions);
    window.addEventListener('resize', this.updateDimensions);
    this.updateDimensions()
  }

  updateDimensions() {
    console.debug('updateDimensions', window.innerWidth)
    var ua = navigator.userAgent.toLowerCase();
    var isApp = ua.indexOf('anbai') > -1
    let element = document.getElementById('main-wrapper');
    if (isApp) {
      element.classList.add('is-app');
    } else {
      element.classList.remove('is-app');
    }
    // let element = document.getElementById('main-wrapper');
    // const width = window.innerWidth
    // if (width < 1170) {
    //   element.setAttribute('data-sidebartype', 'mini-sidebar');
    //   element.classList.add('mini-sidebar');
    // } else {
    //   element.setAttribute(
    //     'data-sidebartype',
    //     'full'
    //   );
    //   element.classList.remove('mini-sidebar');
    // }
  }

  componentWillUnmount() {
    window.removeEventListener('load', this.updateDimensions);
    window.removeEventListener('resize', this.updateDimensions);
  }

  render() {

    const data = this.props.data
    const {allTagsRemark} = data
    const {markdownRemark: post} = data
    const {allCategoriesYaml: yaml} = data
    const {allMarkdownRemark: posts} = data

    const categoryEdges = posts.group[0].edges
    var prev = null
    var next = null
    var index = -1
    categoryEdges.forEach((edge, i) => {
      if(edge.node.id === post.id) {
        index = i
      }
    })
    if (index !== -1) {
      if(index > 0) {
        prev = categoryEdges[index - 1]
      }
      if(index < categoryEdges.length) {
        next = categoryEdges[index + 1]
      }
    }

    const categories = yaml.edges[0].node.data.reduce((obj, category) => {
      obj[category.category] = category
      return obj
    }, {})

    const category = post.frontmatter.category

    return (
      <Layout breadcrumbs={[{name: categories[category].name, link: '/category/' + category}, {name: post.frontmatter.title, link: post.fields.slug}]} lang={post.frontmatter.lang}>
        <ArticlePageTemplate
          content={post.html}
          contentComponent={HTMLContent}
          description={post.frontmatter.description}
          helmet={
            <Helmet>
              <title>{`${post.frontmatter.title} | ${data.site.siteMetadata.title}`}</title>
              <meta
                name="description"
                content={`${data.site.siteMetadata.description}${post.frontmatter.description}`}
              />
            </Helmet>
          }
          prev={prev}
          next={next}
          tags={post.frontmatter.tags}
          title={post.frontmatter.title}
          data={data}
        />
      </Layout>
    )
  }
}

ArticlePage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default ArticlePage

export const pageQuery = graphql`
  query ArticlePageByID($id: String!, $category: String!) {
    site {
      siteMetadata {
        title
        description
      }
    }
    allCategoriesYaml(filter: {data: {elemMatch: {lang: {eq: "ja"}}}}) {
      edges {
        node {
          data {
            category
            name
            icon
          }
        }
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      html
      fields {
        slug
      }
      frontmatter {
        published_date(formatString: "MMMM DD, YYYY")
        modified_date(formatString: "MMMM DD, YYYY")
        title
        description
        category
        tags
        lang
      }
    }
    allMarkdownRemark(
      sort: {fields: [frontmatter___index], order: ASC},
      filter: {frontmatter: {templateKey: { eq: "article-page" }, category: {eq: $category}, invisible:{ne: true}}}
    ) {
      group(field: frontmatter___category) {
        fieldValue
        totalCount
        edges {
          node {
            id
            fields {
              slug
            }
            frontmatter {
              index
              title
            }
          }
        }
      }
    }    
  }
`
