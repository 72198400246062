import React from 'react'
import PropTypes from 'prop-types'
import fetchJsonp from 'fetch-jsonp'
import {Card, CardBody, CardTitle, Button} from 'reactstrap'

class Feedback extends React.Component {

  constructor(props) {
    super(props)
    this.clickOK = this.clickOK.bind(this)
    this.clickNG = this.clickNG.bind(this)
    this.sendComment = this.sendComment.bind(this)
    this.post = this.post.bind(this)
  }

  clickOK() {
    this.post(1, '')
    document.getElementById('button-area').classList.add('hide')
    document.getElementById('thanks-area').classList.remove('hide')
  }

  clickNG() {
    this.post(2, '')
    document.getElementById('button-area').classList.add('hide')
    document.getElementById('comment-area').classList.remove('hide')
  }

  sendComment() {
    const comment = document.getElementById('comment-textarea').value
    this.post(2, comment)
    document.getElementById('comment-area').classList.add('hide')
    document.getElementById('thanks-area').classList.remove('hide')
  }

  post(feedback, comment) {
    const data = {
      URL: window.location.href,
      title: document.getElementsByTagName('title')[0].text,
      feedback: feedback,
      comment: comment,
    }
    // https://docs.google.com/spreadsheets/d/1NlCtRD1nGXNA9bYq9n_dOcuRkov4XRgLtGtnbbt5YdA/edit#gid=0
    const endpoint = 'https://script.google.com/macros/s/AKfycbyZEHhh5ShoggYWYj3xuADLneWDJC4U4GdQQgM3z-wax-k6c_4/exec';
    const url = endpoint + '?URL=' + data.URL + '&title=' + data.title + '&feedback=' + data.feedback + '&comment=' + data.comment;
    fetchJsonp(url).then(function(response) {
      return response.json()
    }).then(function(json) {
      console.log('parsed json', json)
    }).catch(function(ex) {
      console.log('parsing failed', ex)
    })
    // fetch(endpoint, {
      // method: 'POST',
      // headers: {
      //   'Accept': 'application/json',
      //   'Content-Type': 'application/json',
      // },
      // body: JSON.stringify(data)
    // })
  }

  render() {
    const {edges, tag, name} = this.props
    console.debug(edges, tag, name)

    return (
      <Card>
        <CardBody>
          <CardTitle>
            この記事は役に立ちましたか？
          </CardTitle>
          <div className="text-center" id="button-area">
            <Button color="success" onClick={this.clickOK}><i className="fas fa-thumbs-up"></i>役に立った</Button>
            {' '}
            <Button color="warning" onClick={this.clickNG}><i className="fas fa-thumbs-down"></i>役に立たなかった</Button>
          </div>
          <div className="my-3 hide" id="comment-area">
            <p>よろしければ、コメントを下さい。</p>
            <label htmlFor="comment">コメント</label>
            <textarea id="comment-textarea" name="comment" className="form-control my-2" rows={5}></textarea>
            <Button color="info" onClick={this.sendComment}>送信</Button>
          </div>
          <div className="my-3 hide" id="thanks-area">
            <p>貴重なご意見ご感想を頂き、誠にありがとうございました。</p>
          </div>

        </CardBody>
      </Card>
    )
  }
}

Feedback.propTypes = {
  edges: PropTypes.object,
  tag: PropTypes.object,
  name: PropTypes.object,
}

export default Feedback